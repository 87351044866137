<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    title="推送设置"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
  >
    <!-- 列表 -->
    <a-table
      class="table-view"
      :data-source="dataSource"
      :columns="columns"
      :row-key="record => record.id"
      :pagination="pagination"
      :loading="isLoading"
      @change="handleTableChange"
    >
      <!-- 自定义行内容 -->
      <template #bodyCell="{ column, record }">
        <!-- 是否推送 -->
        <template v-if="column.key === 'is_push'">
          {{  record.unlock_count  }}
          <a-switch
            v-model:checked="record.isPush"
            checked-children="开"
            un-checked-children="关"
            @change="switchChange($event, record)"
          />
        </template>
        <!-- 操作 -->
        <template v-if="column.key === 'operation'">
          <a-button type="link" @click="touchSort(record)">调序</a-button>
          <a-button type="link" @click="touchEdit(record)">文案设置</a-button>
        </template>
      </template>
    </a-table>
    <!-- 预览 -->
    <Sort ref="RefSort" @success="getList"></Sort>
    <!-- 简介 -->
    <EditText ref="RefEditText" @success="getList"></EditText>
  </a-drawer>
</template>

<script setup>
import { message } from 'ant-design-vue'
import { ref, reactive, defineExpose } from 'vue'
import EditText from './EditText'
import Sort from './Sort'
import { pushSettinsList, pushIsPush } from '@/api/operate'

// 文案设置
let RefEditText = ref(null)
// 调序
let RefSort = ref(null)
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 平台ID
let platformID = ref(undefined)
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  size: 'small',
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '推送顺序',
    dataIndex: 'sort',
    key: 'sort'
  },
  {
    title: '剧名',
    dataIndex: 'remark_name',
    key: 'remark_name'
  },
  {
    title: '是否推送',
    dataIndex: 'is_push',
    key: 'is_push'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 打开抽屉
function showDrawer (id) {
  platformID.value = id
  visible.value = true
  // 获取剧集列表
  getList()
}

// 获取列表
function getList () {
  isLoading.value = true
  const params = {
    platform_id: platformID.value
    // page: pagination.current,
    // page_size: pagination.pageSize
  }
  pushSettinsList(params).then(res => {
    isLoading.value = false
    const { code, msg, data } = res
    if (code === 0) {
      const list = Array.from(data)
      list.forEach(item => {
        item.isPush = !!item.is_push
      })
      dataSource.value = list
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  }).catch(() => {
    isLoading.value = false
  })
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 重置
    pagination.current = 1
    pagination.pageSize = 10
    visible.value = false
  }
}

// 切换推送开关
function switchChange (e, record) {
  if (!record.description || !record.warm_reminder) {
    message.error('请先设置文案')
    getList()
    return
  }
  isLoading.value = true
  const params = {
    is_push: e ? 1 : 0,
    id: record.id
  }
  pushIsPush(params).then(res => {
    isLoading.value = false
    const { code, msg } = res
    if (code === 0) {
      message.success('切换成功')
    } else {
      message.error(res.message || msg)
      getList()
    }
  }).catch(() => {
    isLoading.value = false
    getList()
  })
}

// 调序
function touchSort (record) {
  RefSort.value.showDrawer(record)
}

// 文案设置
function touchEdit (record) {
  RefEditText.value.showDrawer(record)
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.ant-btn-link {
  padding: 0;
  margin-left: 8px;
} 
</style>