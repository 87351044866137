<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    title="文案设置"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 客服欢迎语 -->
      <a-form-item
        label="剧名"
      >
        {{ detail.drama_name }}
      </a-form-item>
      <!-- 剧集简介 -->
      <a-form-item
        label="剧集简介"
        name="description"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <EmojiTextarea
          placeholder="请输入"
          v-model="formState.description"
          :auto-size="{ minRows: 3, maxRows: 3 }"
          :maxlength="20"
        />
      </a-form-item>
      <!-- 温馨提醒 -->
      <a-form-item
        label="温馨提醒"
        name="warm_reminder"
        :rules="[{ required: true, message: '请输入' }]"
      >
        <EmojiTextarea
          placeholder="请输入"
          v-model="formState.warm_reminder"
          :auto-size="{ minRows: 3, maxRows: 3 }"
          :maxlength="20"
        ></EmojiTextarea>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose, nextTick } from 'vue'
import { message } from 'ant-design-vue'
import Loading from '@/components/Loading'
import Pub from '@/utils/public'
import { pushUpdateContent } from '@/api/operate'
import EmojiTextarea from '@/components/EmojiTextarea'

// emit
const emit = defineEmits(['success'])
// Form实例
const RefForm = ref(null)
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// id
let id = ref(null)
// 详情
let detail = ref(null)
// 表单
let formState = reactive({
  // 剧集简介
  description: undefined,
  // 温馨提示
  warm_reminder: undefined
})

// 打开抽屉
function showDrawer (record) {
  // 展开
  visible.value = true
  // 记录 ID
  detail.value = record
  id.value = record.id
  formState.description = record.description
  formState.warm_reminder = record.warm_reminder
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 重置
    RefForm.value.resetFields()
    visible.value = false
  }
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then(() => {
    isLoading.value = true
    // 组装上传参数
    const params = {
      // 用户id
      id: id.value,
      // 表单数据
      ...formState
    }
    pushUpdateContent(params).then(res => {
      isLoading.value = false
      const { code, msg } = res
      if (code === 0) {
        message.success('提交成功')
        // 更新父组件
        emit('success')
        onClose()
      } else {
        message.error(res.message || msg)
      }
    }).catch(() => {
      message.error('提交失败')
    })
  })
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.form-hide-label /deep/ .ant-form-item-label {
  opacity: 0;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.image-view.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  position: relative;
  width: 118px;
  height: 148px;
  border-radius: 4px;
  overflow: hidden;
  img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.horizontal {
    width: 148px;
    height: 118px;
  }
}
</style>