<template>
  <div class="custom-emoji-textarea-container">
    <!-- 输入框 -->
    <a-textarea
      class="custom-emoji-textarea"
      :value="modelValue"
      :style="{ backgroundColor: 'red' }"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :auto-size="autoSize"
      :allowClear="allowClear"
      :showCount="showCount"
      @focus="onFocus"
      @blur="onBlur"
      @change="onChange"
    >
    </a-textarea>
    <!-- 表情 -->
    <SmileOutlined
      v-if="isEmoji"
      class="custom-emoji-icon"
      @click.stop="showEmoji"
    />
    <!-- emoji -->
    <EmojiPicker
      v-if="isVisibleEmoji"
      class="custom-emoji-picker"
      :native="true"
      :hide-search="true"
      :hide-group-names="true"
      :hide-group-icons="true"
      @select="onSelectEmoji"
      @click.stop
    />
  </div>
</template>

<script setup>
import { onBeforeMount, onBeforeUnmount, ref, watchEffect } from 'vue'
// 需要安装 $ npm i vue3-emoji-picker（附：https://www.npmjs.com/package/vue3-emoji-picker）
import EmojiPicker from 'vue3-emoji-picker'
import 'vue3-emoji-picker/dist/style.css'

// 输入框内容
let content = ref('')
// 光标位置
let caretPosition = ref(0)
// 是否有首次获取过焦点
let isFirstFocus = ref(false)
// 是否显示 Emoji
let isVisibleEmoji = ref(false)

// 外抛事件
const emit = defineEmits(['change', 'focus', 'blur', 'update:modelValue'])

// 接收参数
const props = defineProps({
  // 支持 Emoji 表情
  isEmoji: {
    type: Boolean,
    default: true
  },
  // 内容
  modelValue: {
    type: String,
    default: ''
  },
  // 可以拿到修饰符
  modelModifiers: {
    type: Object,
    default: () => {}
  },
  // 描述
  placeholder: {
    type: String,
    default: ''
  },
  // 可以点击清除图标删除内容
  allowClear: {
    type: Boolean,
    default: true
  },
  // 是否展示字数
  showCount: {
    type: Boolean,
    default: false
  },
  // 自适应内容高度，可设置对象：{ minRows: 2, maxRows: 6 }
  autoSize: {
    type: Object,
    default: () => {}
  },
  // 最大长度
  maxlength: {
    type: Number,
    default: 0
  }
})

// DOM即将挂载
onBeforeMount(() => {
  window.addEventListener('click', windowClick)
})

// 即将销毁
onBeforeUnmount(() => {
  window.removeEventListener('click', windowClick)
})

// 点击窗口
function windowClick () {
  isVisibleEmoji.value = false
}

// 选中 Emoji
function onSelectEmoji(emoji) {
  const content = props.modelValue || ''
  if (props.maxlength === 0 || content.length < props.maxlength) {
    const { position, value } = insertStr(content, caretPosition.value, emoji.i)
    caretPosition.value = position
    updated(value)
  } else {
    updated(content)
  }
}

// 更新
function updated (value) {
  emit('update:modelValue', value)
  emit('change', value)
}

// 显示表情窗口
function showEmoji () {
  onBlur()
  isVisibleEmoji.value = !isVisibleEmoji.value
}

// 获得焦点
function onFocus () {
  isFirstFocus.value = true
  isVisibleEmoji.value = false
  emit('focus', props.modelValue)
}

// 失去焦点
function onBlur () {
  if (isFirstFocus) {
    // 获取光标位置
    let el = document.getElementsByClassName('custom-emoji-textarea')[0]
    el = el.getElementsByClassName('custom-emoji-textarea')[0]
    caretPosition.value = getCaretPosition(el)
  } else {
    // 还没首次获取过光标，直接获取字符串长度
    caretPosition.value = content.length
  }
  emit('blur', props.modelValue)
}

// 内容改变
function onChange (e) {
  updated(e.target.value)
}

// souece 原字符串 start 要截取的位置 newStr 要插入的字符
function insertStr(source, start, newStr) {
  const frontStr = source.slice(0, start) + newStr
	return {
    position: frontStr.length,
    value: frontStr + source.slice(start)
  }
}

// 获取输入框光标位置
function getCaretPosition(element) {
  // 对于支持 selectionStart 的元素，直接使用该属性
  if (typeof element.selectionStart === 'number') {
    return element.selectionStart
  } else if (document.selection) {
    // 对于IE < 9
    // 创建一个 TextRange 来获取光标位置
    var range = document.selection.createRange()
    // 将 Range 移动到输入框的开始位置
    range.moveStart('character', -element.value.length)
    // selection 属性返回的是当前选中文本的长度，所以减去它就是光标位置
    return range.text.length
  }
  return 0
}
</script>

<style scoped>
.custom-emoji-textarea-container {
  position: relative;
}
.custom-emoji-textarea {
  width: 100%;
  height: 100%;
}
.custom-emoji-picker {
  position: absolute;
  right: 0;
  z-index: 1000;
}
.custom-emoji-icon {
  position: absolute;
  bottom: 8px;
  right: 6px;
  cursor: pointer;
  z-index: 999;
}
</style>
